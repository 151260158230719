<template>
    <page-header-wrapper>
        <a-card :bordered="false">
            <div class="table-page-search-wrapper" v-action:query>
                <a-form layout="inline">
                    <a-row :gutter="24">
                        <a-col :md="8" :sm="24">
                            <a-form-item label="时间筛选：">
                                <a-range-picker @change="getDate" v-model="queryParam.time" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-form-item label="操作人">
                                <a-input style="width: 80%" placeholder="请输入" v-model="queryParam.key" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="4 || 24" :sm="24">
                            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                                <a-button style="margin-left: 8px" @click="reset">重置</a-button>
                            </span>
                        </a-col>
                    </a-row>
                </a-form>
            </div>

            <s-table ref="table" size="default" :rowKey="(record) => record.id" :columns="columns" :data="loadData" showPagination="auto">
                <span slot="serial" slot-scope="text, record, index">
                    {{ index + 1 }}
                </span>
                <span slot="action" slot-scope="operate_type">
                    <a-tag color="#0091FF">{{operate_type}}</a-tag>
					<!-- <a-tag color="#E02020">删除</a-tag>
					<a-tag color="#FA6400">编辑</a-tag> -->
                </span>
                <span slot="content" slot-scope="operate_content,record">
					<span class="cTitle">[{{record.operate_module}}]</span> {{operate_content}}
                </span>
				<span slot="op" slot-scope="username">
					{{username}}
				</span>
				<span slot="opTime" slot-scope="createtime">
					{{createtime|time}}
				</span>
				<span slot="ip" slot-scope="createip">
					{{createip}}
				</span>
				<span slot="os" slot-scope="os">
					windows
				</span>
            </s-table>
        </a-card>
    </page-header-wrapper>
</template>

<script>
    import moment from 'moment'
    import { Modal,message } from 'ant-design-vue';
    import {
        STable,
        Ellipsis
    } from '@/components'
    import {
        getOpLogList,
        addOutlets,
		delOutlets
    } from '@/api/log'

    const columns = [
        // {
        //     title: '序号',
        //     scopedSlots: {
        //         customRender: 'serial'
        //     },
        //     align:'center',
        //     width:'90px'
        // },
		{
		    title: '操作行为',
		    dataIndex: 'operate_type',
		    align:'center',
			scopedSlots: {
			    customRender: 'action'
			},
			width:'150px'
		},
		{
		    title: '操作内容',
		    dataIndex: 'operate_content',
		    align:'center',
			scopedSlots: {
			    customRender: 'content'
			},
			width:'280px'
		},
		{
		    title: '操作人',
		    dataIndex: 'username',
			scopedSlots: {
			    customRender: 'op'
			},
		    align:'center',
			width:'120px'
		},
        {
            title: '操作时间',
            dataIndex: 'createtime',
            align:'center',
			scopedSlots: {
			    customRender: 'opTime'
			},
			width:'150px'
        },
		
        {
            title: '提交IP',
            dataIndex: 'createip',
            align:'center',
            scopedSlots: {
                customRender: 'ip'
            },
			width:'150px'
        }
    ]

    const statusMap = {
        0: {
            status: 'default',
            text: '全部'
        },
        1: {
            status: 'default',
            text: '待审核'
        },
        2: {
            status: 'success',
            text: '审核通过'
        },
        3: {
            status: 'error',
            text: '审核拒绝'
        }
    }

    export default {
        name: '',
        components: {
            STable,
            Ellipsis
        },
        data() {
            this.columns = columns
            return {
                visible: false,
                advanced: false,
                // 查询参数
                queryParam: {
                    time: [],
                    key: '',
                    startTime: '',
                    endTime: '',
                },
                // 加载数据方法 必须为 Promise 对象
                loadData: parameter => {
                    //console.log('parameter',parameter)
                    const requestParameters = Object.assign({}, parameter, this.queryParam)
                    //console.log('requestParameters',requestParameters)
                    return getOpLogList(requestParameters)
                        .then(res => {
                            return res.data
                        })
                },
                
                modalVisible: false, //发布弹窗
                url:'',   //微信文章url

                title:'',
                img_url:'',
                link_url:'',
				
				form:{
					id:'',
					name:'',
					address:'',
					lat:'',
					lng:''
					
				},
				selectedAdd: undefined,
				addressData:[]
            }
        },
		mounted(){
			
		},
        methods: {
            getDate(date, dateString) { //时间筛选
                this.queryParam.time = dateString
                this.queryParam.startTime = this.queryParam.time[0] ? this.queryParam.time[0]+' 00:00:00': ''
                this.queryParam.endTime = this.queryParam.time[1] ? this.queryParam.time[1]+' 23:59:59': ''
            },
            reset() { //重置
                this.queryParam.time = null
				this.queryParam.startTime = ''
				this.queryParam.endTime = ''
                this.queryParam.key = ''
                this.$refs.table.refresh(true)
            }
        },
        filters: {
            time(t) {
                if (t) {
                    return moment(parseInt(t) * 1000).format('YYYY/MM/DD HH:mm:ss')
                } else {
                    return t
                }
            },
            statusFilter(type) {
                return statusMap[type].text
            },
            statusTypeFilter(type) {
                return statusMap[type].status
            }
        }
    }
</script>


<style scoped>
.cTitle{cursor:pointer;color: #0091FF;}
</style>