import request from '@/utils/request'
const isProd = process.env.NODE_ENV === 'production'


const noticeApi = {
	getOpLogList: (isProd ? '' : '/api') + '/newAdmin.php?c=Log&a=getOperateLogList',
	getLogList: (isProd ? '' : '/api') + '/newAdmin.php?c=Log&a=getLoginLogList',
}

/*操作日志列表*/
export function getOpLogList(parameter) {
	return request({
		url: noticeApi.getOpLogList,
		method: 'post',
		data: parameter
	})
}

/*登陆日志列表*/
export function getLogList(parameter) {
	return request({
		url: noticeApi.getLogList,
		method: 'post',
		data: parameter
	})
}